import React, { useState, useContext, ReactNode } from "react"

export type Locale = "nl" | "gb"

type Value = {
  visited: boolean
  setVisited: React.Dispatch<React.SetStateAction<boolean>>
}

interface Props {
  children: ReactNode
}

const VisitedContext = React.createContext<Value | null>(null)

const VisitedContextProvider = (props: Props) => {
  const [visited, setVisited] = useState(false)

  return (
    <VisitedContext.Provider value={{ visited, setVisited } as Value}>
      {props.children}
    </VisitedContext.Provider>
  )
}

const useVisitedContext = () => useContext(VisitedContext) as Value
const useIsVisited = () => useContext(VisitedContext)!
export { useIsVisited, useVisitedContext, VisitedContextProvider }
