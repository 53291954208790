import React from "react"
import { makeStyles } from "@material-ui/core"
import { RotateLoader } from "react-spinners"
import { colors } from "../../styles/colors"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}))

interface Props {}

const FullScreenLoader: React.FC<Props> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <RotateLoader color={colors.secondary} />
    </div>
  )
}

export default FullScreenLoader
