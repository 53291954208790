import { Switch, Route } from "react-router-dom"
import React, { Suspense } from "react"
import smoothscroll from "smoothscroll-polyfill"

//  Hooks
import { useAos } from "./hooks/useAos"

import FullScreenLoader from "./components/common/FullScreenLoader"

smoothscroll.polyfill()

const Schedule = React.lazy(
  () => import("./components/projects/schedule/Schedule")
)
const ScrollPage = React.lazy(() => import("./ScrollPage"))

interface Props {}

const App: React.FC<Props> = () => {
  useAos()

  console.log(
    "%cWow nice, you've found a clue :-). The clue is: what happens if your name is Easter Bunny?",
    "color: green; font-size: 20px"
  )

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Switch>
        <Route path="/schedule" component={Schedule} />
        <Route exact path="/" component={ScrollPage} />
      </Switch>
    </Suspense>
  )
}

export default App
