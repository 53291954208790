import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { MuiThemeProvider } from "@material-ui/core"
import { theme } from "./styles/theme"
import "./styles/css/normalize.css"
import "./styles/css/global.css"
import { BrowserRouter } from "react-router-dom"
import { VisitedContextProvider } from "./context/visitedContext"

ReactDOM.render(
  <React.Fragment>
    <VisitedContextProvider>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </BrowserRouter>
    </VisitedContextProvider>
  </React.Fragment>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
